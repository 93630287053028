@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.SelectGame {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../assets/images/backgrounds/select-game.svg');
	background-size: cover;

	.SelectGame-games{
		width: 100%;
		height: 100%;
		@include flex('center', 'center', 'column');
		
		.SelectGame-game {
			width: 29em;
			height: 6.5em;
			background-size: 28.5em;
			cursor:pointer;
			&.game1 {
				background-image: url('../../assets/images/logo-game1.svg');
			}
			&.game2 {
				margin-top: 4em;
				background-image: url('../../assets/images/logo-game2.svg');
			}
			&:hover {
				background-size: contain;
			}

		}	
	}

	.SelectGame-adminBtn {
		position: absolute;
		left: 1em;
		bottom: 1em;
	}
}