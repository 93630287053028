@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.Area {
	@include flex('','','column');
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 2em;
	
	.Area-header {
		.Area-logo {
			position: relative;
			padding-left: 2.8em;
			margin-right: 31.45em;
		}
		@include flex();
		margin-bottom: 1.60em;
		.Area-groupName {
			margin-left: 1em;
		}
	}

	.Area-contentWrapper {
		@include flex('','','row');
		.Area-sideWrapper {
			position: relative;
			height: 100%;
			padding: 1.9em 0 0 2.8em;
			@include flex('', '', 'column');
			.Area-infoWindow {
				position: relative;
			}
		}
	
		.Area-middleWrapper {
			@include flex('', '', 'column');
			
			.Area-areaWrapper {
				width: 100%;
				height: 100%;
				.Area-lines {
					.Area-line {
						position: absolute;
						top: 23.66em;
						left: 53.22em;
						background-color: $darkBlue;
						height: 0.33em;
						width: 0em;
						pointer-events: none;
						@include origin(0, 0);
						&:nth-child(1) {
							@include growWidth(0.5s, 0.5s, 21em);
							@include rotate(140deg);
						}
						&:nth-child(2) {
							@include growWidth(0.5s, 0.5s, 22em);
							@include rotate(80deg);
						}
						&:nth-child(3) {
							@include growWidth(0.5s, 0.5s, 25em);
							@include rotate(25deg);
						}
						&:nth-child(4) {
							@include growWidth(0.5s, 0.5s, 30em);
							@include rotate(110deg);
						}
						&:nth-child(5) {
							@include growWidth(0.5s, 0.5s, 36em);
							@include rotate(50deg);
						}
						&:nth-child(6) {
							@include growWidth(0.5s, 0.5s, 28em);
							@include rotate(38deg);
						}
						&:nth-child(7) {
							@include growWidth(0.5s, 0.5s, 35em);
							@include rotate(10deg);
						}
					}
				}
				.Area-areaButton {
					position: absolute;
					top: 9.6em;
					left: 44.16em;
					z-index: 2;

					&.research {
						@include moveFromPoint(0.5s, 0s, 16em, 34.7em);
					}
					&.it {
						@include moveFromPoint(0.5s, 0s, 33.83em, 34.7em);
					}
					&.build {
						@include moveFromPoint(0.5s, 0s, 8em, 56.44em);
					}
					&.prep {
						@include moveFromPoint(0.5s, 0s, 41.83em, 56.44em);
					}
					&.design {
						@include moveFromPoint(0.5s, 0s, 16em, 78.16em);
					}
					&.production {
						@include moveFromPoint(0.5s, 0s, 33.83em, 78.16em);
					}

					&.sales {
						@include moveFromPoint(0.5s, 0s, 9.94em, 55.96em);
					}
					&.management{
						@include moveFromPoint(0.5s, 0s, 41.5em, 67.96em);
					}
					&.marketing {
						@include moveFromPoint(0.5s, 0s, 24.83em, 75.41em);
					}
					&.economy {
						@include moveFromPoint(0.5s, 0s, 41.5em, 43.96em);
					}
				}
				.Area-cases {
					position: absolute;
					top: 25.25em;
					width: 100%;
					height: 100%;

					@include flex('flex-start', 'flex-start');
					.Area-casesButton {
						position: absolute;
						min-width: 19.44em;
						height: 3em;
						@include transition(0.1s);
						@include opacity(0);					
						.Area-completionIndicator {
							position: absolute;
							@include flex('center', 'center');
							pointer-events: none;
							right: 0.7em;
							height: 1.55em;
							width: 3.7em;
							bottom: calc(50% - 0.6em);
							background-color: $greyBlue;
							
							border-radius: 0.8em;
							@include box-inner-shadow(0, 0.27em, 0, 0, $blueGrey);
							
							&.game2 {
								@include box-inner-shadow(0, 0.27em, 0, 0, $beigeDark);
							}
							
							.Area-completionIndicatorFill {
								@include box-inner-shadow(0, 0.27em, 0, 0, $blueGrey);
								position: absolute;
								left: 0;
								border-top-left-radius: 0.8em;
								border-bottom-left-radius: 0.8em;
								width: 75%;
								height: 100%;
								background-color: $gold;

								&.game2 {
									@include box-inner-shadow(0, 0.27em, 0, 0, $beigeDark);
								}

								&.rounded {
									border-radius: 0.8em;
								}
							}

							span {
								padding-top: 0.3em;
								position: relative;
								font-size: 1.1em;
								font-weight: bold;
							}
						}

						&:hover {
							@include scale(1.1);
						}
						&:nth-child(1) {
							left: -3em;
							top: 11em;
							@include moveFromPoint(0.5s, 0.5s, -1.59em, 16.11em);
						}
						&:nth-child(2) {
							left: 20.2em;
							top: 18.33em;
							@include moveFromPoint(0.5s, 0.5s, -1.59em, 16.11em);
						}
						&:nth-child(3) {
							left: 43.38em;
							top: 8.52em;
							@include moveFromPoint(0.5s, 0.5s, -1.59em, 16.11em);
						}
						&:nth-child(4) {
							left: 6.38em;
							top: 25.66em;
							@include moveFromPoint(0.5s, 0.5s, -1.59em, 16.11em);
						}
						&:nth-child(5) {
							left: 40.38em;
							top: 25.66em;
							@include moveFromPoint(0.5s, 0.5s, -1.59em, 16.11em);
						}
						&:nth-child(6) {
							left: 45.38em;
							top: 15.66em;
							@include moveFromPoint(0.5s, 0.5s, -1.59em, 16.11em);
						}
						&:nth-child(7) {
							left: 50em;
							top: 3.66em;
							@include moveFromPoint(0.5s, 0.5s, -1.59em, 16.11em);
						}
					}
				}
				.Area-infoWindow {
					margin-left: 7.38em;
				}
			}
		}
	}
	
	.Area-backBtn {
		position: absolute;
		left: 2.8em;
		bottom: 2em;
		width: 14.44em;
	}	
}