@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';
@import '../styles/buttons';
.App {
	position: relative;
	height: 100%;

	.App-logo {
		position: absolute;
		background-image: url(../assets/images/logo-unord.png);
		width: 10.25em;
		height: 1.75em;
		z-index: 3;
		top: 1em;
		right: 1em;
	}

	.App-audioWrapper {
		position: absolute;
		top: 3em;
		right: 2em;
		z-index: 2;
	}
}