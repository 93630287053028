@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.GameCode {
	position: absolute;
	@include flex('flex-start', 'center', 'column');
	top: 1.5em;
	height: 5.66em;
	min-width: 14.11em;

	span {
		font-size: 1.67em;
		font-weight: 900;
		text-transform: uppercase;
	}
	:nth-child(2) {
		@include flex('center', 'center');
		background-color: white;
		padding-left: 0.5em;
		padding-right: 0.5em;
		color: $darkBlue;
		width: 100%;
		height: 3em;
		border-radius: 1.5em;
		margin-top: 0.5em;
	}
}