@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Countdown {
	@include flex('center', 'center', 'column');
	position: relative;
	width: 100%;
	height: 100%;
	&.small {
		@include flex('flex-start', 'center', 'column');
		margin-left: 20.44em;
	}
	
	.Countdown-timeout {
		@include fontBlink(1s);
		height: 3em;
		margin-top: -4em;
		margin-bottom: 1em;
		span {
			font-size: 2em;
			text-transform: uppercase;
			font-weight: bold;
		}
	}

	.Countdown-wrapper {
		position: relative;
		@include flex('flex-start','flex-start','row');
	

		&.default {
			@include flex('','center','row');
			.Countdown-box {
				width: 8.05em;
				height: 10.61em;
				margin: 0 0.4em;
				@include flex('center','center');
				span {
					font-size: 5em;
				}
			}
			.Countdown-colon {
				width: 1.5em;
				height: 4.5em;
				margin: 0 0.5em;
			}
		}

		.Countdown-box {
			padding: 2em;
			background-color: darken(white, 5%);

			border-radius: 0.56em;

			border-top: solid;
			border-top-color: lighten(white, 5%);
			border-top-width: 0.25em;

			@include box-shadow(0em, 0.5em, 0, 0, darken(white, 10%));

			span {
				color: $darkBlue;
				font-weight: bold;
			}

			&.timesOut {
				span {
					@include fontBlink(1s);
				}
			}
		}

		.Countdown-colon {
			background-image: url('../../../assets/images/icons/icon-colon.svg');
			background-size: contain;
		}

		&.small {
			@include flex('flex-start','center','row');
			height: 5.33em;
			width: 18.94em;
			margin-top: 2.38em;
			.Countdown-box {
				width: 4em;
				height: 5.33em;
				@include flex('center','center');
				margin-left: 0.25em;
				margin-right: 0.25em;
				span {
					font-size: 2.5em;
				}
			}
			.Countdown-colon {
				width: 0.72em;
				height: 2.33em;
				margin: 0 0.25em;
			}
		}
	}
}