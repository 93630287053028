@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Background {
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;

	.Background-image {
		height: auto;
		width: 100%;
		top: 0;
	}

	.Background-login {
		#cloud-1
		{
			@include floatRight(85s, -30s);
		}
		#cloud-2
		{
			@include floatRight(95s, -10s);
		}
		#cloud-3
		{
			@include floatRight(110s, -70s);
		}
		#cloud-4
		{
			@include floatRight(130s, -20s);
		}
	}
}