@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.Admin {
	position: relative;
	height: 100%;
	padding: 11.5em 3.75em 0 3.75em;

	.Admin-logo {
		position: absolute;
		top: 0em;
		left: 0em;
	}

	.Admin-gameBtn {
		position: absolute;
		left: 3.75em;
		bottom: 3em;
	}

	.Admin-addUser {
		width: 30em;
		margin-bottom: 3em;
		@include flex('space-between', 'center');
		.Admin-addUserInput {
			input {
				display: block;
				font-size: 1.11em;
				line-height: 1;
				width: 15em;
				height: 2.5em;
				border: 1px solid #EF571D;
				border-radius: 0.3em;
				padding: 1em;
				background-color: white;
				color: #292929;
				font-weight: normal;
				&::placeholder {color: rgba(#292929, 0.4);}
			}
		}
	}

	.Admin-users {
		display: inline-block;
		background-color: white;
		color: #292929;
		.Admin-usersItem {
			width: 15em;
			height: 2.5em;
			padding: 0 1em;
			@include flex('flex-start', 'center');
			&.delete {
				width: 5em;
			}
		}
		.Admin-usersHeader {
			background-color: #EF571D;
			color: white;
			font-weight: bold;
			@include flex('space-between', 'center');
		}
		.Admin-usersRow {
			@include flex('space-between', 'center');
		}
	}

}