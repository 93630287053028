@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.AreaHub {
	@include flex('','','row');
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 2em;

	.AreaHub-sideWrapper {
		position: relative;
		padding-left: 2.8em;
		@include flex('flex-start', '', 'column');
		.AreaHub-logo {
			position: relative;
			text-align: center;
		}
		.AreaHub-infoWindow {
			position: relative;
			margin-top: 1.9em;
		}
	}

	.AreaHub-middleWrapper {
		@include flex('', '', 'column');
		.AreaHub-header {
			@include flex();
			margin-bottom: 1.55em;
			margin-left: 29.5em;

			.AreaHub-groupName {
				margin-left: 1em;
			}
		}

		.AreaHub-areaWrapper {
			@include flex('space-between', '', 'row');
			height: 48.4em;
			width: 62.9em;
			margin-left: 7.2em;

			.AreaHub-areaButton {
				position: relative;
			}

			.AreaHub-areaButtons {
				margin-top: 7em;
				@include fadeIn(0.5s);
				
				&.game1 {
					.AreaHub-areaButton-0 {
						position: absolute;
						top: 16em;
						left: 34.7em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
					.AreaHub-areaButton-1 {
						position: absolute;
						top: 33.83em;
						left: 34.7em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
					.AreaHub-areaButton-2 {
						position: absolute;
						top: 8em;
						left: 56.44em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
					.AreaHub-areaButton-3 {
						position: absolute;
						top: 41.83em;
						left: 56.44em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
					.AreaHub-areaButton-4 {
						position: absolute;
						top: 16em;
						left: 78.16em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
					.AreaHub-areaButton-5 {
						position: absolute;
						top: 33.83em;
						left: 78.16em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
				}
				&.game2 {
					.AreaHub-areaButton-0 {
						position: absolute;
						top: 9.94em;
						left: 55.96em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
					.AreaHub-areaButton-1 {
						position: absolute;
						top: 24.83em;
						left: 75.41em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
					.AreaHub-areaButton-2 {
						position: absolute;
						top: 24.83em;
						left: 36.52em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
					.AreaHub-areaButton-3 {
						position: absolute;
						top: 41.5em;
						left: 67.46em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
					.AreaHub-areaButton-4 {
						position: absolute;
						top: 41.5em;
						left: 44.56em;
						@include moveFromPoint(0.5s, $top: calc(50%), $left: calc(50%));
					}
				}
			}
		}
	}
}