@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.LandingPageFacilitator {
	height: 100%;
	width: 100%;
	@include flex('center', 'center', 'column');
	.LandingPageFacilitator-logoWrapper {
		position: absolute;
		top: 9.78em;
	}
	.LandingPageFacilitator-welcome {
		position: absolute;
		top: 6.28em;
		span {
			font-size: 2.12em;
			color: $darkBlue;
			text-transform: uppercase;
			font-weight: 950;
		}
	}
	
	.LandingPageFacilitator-content {
		position: relative;
		width: 24.73em;
		height: 23.23em;
		background-color: white;
		border-radius: 0.56em;
		padding: 3.2em 2.5em 2.5em 2.5em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));
		margin-top: 5.39em;
		.LandingPageFacilitator-title { 
			text-align: left;
			margin-bottom: 0.75em;
			span {
				font-size: 1.34em;
				font-weight: 500;
				color: $darkBlue;
			}
		}
		.LandingPageFacilitator-text { 
			text-align: left;
			margin-bottom: 1.73em;
			span {
				font-size: 1.17em;
				color: $darkBlue;
			}
		}

		.LandingPageFacilitator-buttons {
			margin: auto;
			width: 18em;
			height: 3em;
			.LandingPageFacilitator-newGame {
				width: 100%;
			}
			.LandingPageFacilitator-continueGame {
				margin-top: 1.34em;
				width: 100%;
			}
		}
	}
	.LandingPageFacilitator-logoutBtn {
		position: absolute;
		top: 1em;
		left: 1em;
	}
	.LandingPageFacilitator-adminBtn {
		position: absolute;
		top: 6em;
		left: 1em;
	}

	.LandingPageFacilitator-backBtn {
		position: absolute;
		width: 10em;
		bottom: 1em;
		left: 1em;
	}
}
