@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.PopupDeleteUser {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	@include flex('center', 'center', 'column');
	background: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	z-index: 2;

	.PopupDeleteUser-dialogue {
		position: relative;
		background-color: white;
		border-radius: 0.56em;
		padding: 2.2em 2.5em 2.5em 2.5em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));
		margin-top: 5.39em;
		@include flex('space-between', 'center', 'column');
		@include no-select();

		.PopupDeleteUser-title {
			margin-bottom: 1.5em;
			span {
				font-size: 1.33em;
				font-weight: 700;
				color: $darkBlue;
			}
		}

		.PopupDeleteUser-warning {
			text-align: left;
			margin-bottom: 1.72em;
			span {
				margin: 0;
				font-size: 1.17em;
				color: $darkBlue;
			}
		}

		.PopupDeleteUser-popupButtons {
			@include flex('center', 'center', 'row');

			.PopupDeleteUser-confirmBtn {
				margin-right: 0.5em;
				width: 100%;
			}
			.PopupDeleteUser-cancelBtn {
				margin-left: 0.5em;
				width: 100%;
			}
		}
	}
}